/* Raster v20 (grid only) (rsms.me/raster) */
r-grid {
  display: grid;
  --grid-tc: repeat(4, 1fr);
  grid-template-columns: var(--grid-tc);
  --grid-cs: 1;
  --grid-ce: -1;
}
r-grid > r-cell {
  display: block;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  min-width: 0;
}
r-grid[columns='1'] {
  --grid-tc: repeat(1, 1fr);
}
r-grid[columns='2'] {
  --grid-tc: repeat(2, 1fr);
}
r-grid[columns='3'] {
  --grid-tc: repeat(3, 1fr);
}
r-grid[columns='4'] {
  --grid-tc: repeat(4, 1fr);
}
r-grid[columns='5'] {
  --grid-tc: repeat(5, 1fr);
}
r-grid[columns='6'] {
  --grid-tc: repeat(6, 1fr);
}
r-grid[columns='7'] {
  --grid-tc: repeat(7, 1fr);
}
r-grid[columns='8'] {
  --grid-tc: repeat(8, 1fr);
}
r-grid[columns='9'] {
  --grid-tc: repeat(9, 1fr);
}
r-grid[columns='10'] {
  --grid-tc: repeat(10, 1fr);
}
r-grid[columns='11'] {
  --grid-tc: repeat(11, 1fr);
}
r-grid[columns='12'] {
  --grid-tc: repeat(12, 1fr);
}
r-grid[columns='13'] {
  --grid-tc: repeat(13, 1fr);
}
r-grid[columns='14'] {
  --grid-tc: repeat(14, 1fr);
}
r-grid[columns='15'] {
  --grid-tc: repeat(15, 1fr);
}
r-grid[columns='16'] {
  --grid-tc: repeat(16, 1fr);
}
r-grid[columns='17'] {
  --grid-tc: repeat(17, 1fr);
}
r-grid[columns='18'] {
  --grid-tc: repeat(18, 1fr);
}
r-grid[columns='19'] {
  --grid-tc: repeat(19, 1fr);
}
r-grid[columns='20'] {
  --grid-tc: repeat(20, 1fr);
}
r-grid[columns='21'] {
  --grid-tc: repeat(21, 1fr);
}
r-grid[columns='22'] {
  --grid-tc: repeat(22, 1fr);
}
r-grid[columns='23'] {
  --grid-tc: repeat(23, 1fr);
}
r-grid[columns='24'] {
  --grid-tc: repeat(24, 1fr);
}
r-grid[columns='25'] {
  --grid-tc: repeat(25, 1fr);
}
r-grid[columns='26'] {
  --grid-tc: repeat(26, 1fr);
}
r-grid[columns='27'] {
  --grid-tc: repeat(27, 1fr);
}
r-grid[columns='28'] {
  --grid-tc: repeat(28, 1fr);
}
r-grid[columns='29'] {
  --grid-tc: repeat(29, 1fr);
}
r-grid[columns='30'] {
  --grid-tc: repeat(30, 1fr);
}
r-grid > r-cell[span^='1'] {
  --grid-cs: 1;
}
r-grid > r-cell[span^='2'] {
  --grid-cs: 2;
}
r-grid > r-cell[span^='3'] {
  --grid-cs: 3;
}
r-grid > r-cell[span^='4'] {
  --grid-cs: 4;
}
r-grid > r-cell[span^='5'] {
  --grid-cs: 5;
}
r-grid > r-cell[span^='6'] {
  --grid-cs: 6;
}
r-grid > r-cell[span^='7'] {
  --grid-cs: 7;
}
r-grid > r-cell[span^='8'] {
  --grid-cs: 8;
}
r-grid > r-cell[span^='9'] {
  --grid-cs: 9;
}
r-grid > r-cell[span^='10'] {
  --grid-cs: 10;
}
r-grid > r-cell[span^='11'] {
  --grid-cs: 11;
}
r-grid > r-cell[span^='12'] {
  --grid-cs: 12;
}
r-grid > r-cell[span^='13'] {
  --grid-cs: 13;
}
r-grid > r-cell[span^='14'] {
  --grid-cs: 14;
}
r-grid > r-cell[span^='15'] {
  --grid-cs: 15;
}
r-grid > r-cell[span^='16'] {
  --grid-cs: 16;
}
r-grid > r-cell[span^='17'] {
  --grid-cs: 17;
}
r-grid > r-cell[span^='18'] {
  --grid-cs: 18;
}
r-grid > r-cell[span^='19'] {
  --grid-cs: 19;
}
r-grid > r-cell[span^='20'] {
  --grid-cs: 20;
}
r-grid > r-cell[span^='21'] {
  --grid-cs: 21;
}
r-grid > r-cell[span^='22'] {
  --grid-cs: 22;
}
r-grid > r-cell[span^='23'] {
  --grid-cs: 23;
}
r-grid > r-cell[span^='24'] {
  --grid-cs: 24;
}
r-grid > r-cell[span^='25'] {
  --grid-cs: 25;
}
r-grid > r-cell[span^='26'] {
  --grid-cs: 26;
}
r-grid > r-cell[span^='27'] {
  --grid-cs: 27;
}
r-grid > r-cell[span^='28'] {
  --grid-cs: 28;
}
r-grid > r-cell[span^='29'] {
  --grid-cs: 29;
}
r-grid > r-cell[span^='30'] {
  --grid-cs: 30;
}
r-grid > r-cell[span$='+1'],
r-grid > r-cell[span='1'] {
  --grid-ce: 1;
}
r-grid > r-cell[span$='+2'],
r-grid > r-cell[span$='-1'],
r-grid > r-cell[span='2'] {
  --grid-ce: 2;
}
r-grid > r-cell[span$='+3'],
r-grid > r-cell[span$='-2'],
r-grid > r-cell[span='3'] {
  --grid-ce: 3;
}
r-grid > r-cell[span$='+4'],
r-grid > r-cell[span$='-3'],
r-grid > r-cell[span='4'] {
  --grid-ce: 4;
}
r-grid > r-cell[span$='+5'],
r-grid > r-cell[span$='-4'],
r-grid > r-cell[span='5'] {
  --grid-ce: 5;
}
r-grid > r-cell[span$='+6'],
r-grid > r-cell[span$='-5'],
r-grid > r-cell[span='6'] {
  --grid-ce: 6;
}
r-grid > r-cell[span$='+7'],
r-grid > r-cell[span$='-6'],
r-grid > r-cell[span='7'] {
  --grid-ce: 7;
}
r-grid > r-cell[span$='+8'],
r-grid > r-cell[span$='-7'],
r-grid > r-cell[span='8'] {
  --grid-ce: 8;
}
r-grid > r-cell[span$='+9'],
r-grid > r-cell[span$='-8'],
r-grid > r-cell[span='9'] {
  --grid-ce: 9;
}
r-grid > r-cell[span$='+10'],
r-grid > r-cell[span$='-9'],
r-grid > r-cell[span='10'] {
  --grid-ce: 10;
}
r-grid > r-cell[span$='+11'],
r-grid > r-cell[span$='-10'],
r-grid > r-cell[span='11'] {
  --grid-ce: 11;
}
r-grid > r-cell[span$='+12'],
r-grid > r-cell[span$='-11'],
r-grid > r-cell[span='12'] {
  --grid-ce: 12;
}
r-grid > r-cell[span$='+13'],
r-grid > r-cell[span$='-12'],
r-grid > r-cell[span='13'] {
  --grid-ce: 13;
}
r-grid > r-cell[span$='+14'],
r-grid > r-cell[span$='-13'],
r-grid > r-cell[span='14'] {
  --grid-ce: 14;
}
r-grid > r-cell[span$='+15'],
r-grid > r-cell[span$='-14'],
r-grid > r-cell[span='15'] {
  --grid-ce: 15;
}
r-grid > r-cell[span$='+16'],
r-grid > r-cell[span$='-15'],
r-grid > r-cell[span='16'] {
  --grid-ce: 16;
}
r-grid > r-cell[span$='+17'],
r-grid > r-cell[span$='-16'],
r-grid > r-cell[span='17'] {
  --grid-ce: 17;
}
r-grid > r-cell[span$='+18'],
r-grid > r-cell[span$='-17'],
r-grid > r-cell[span='18'] {
  --grid-ce: 18;
}
r-grid > r-cell[span$='+19'],
r-grid > r-cell[span$='-18'],
r-grid > r-cell[span='19'] {
  --grid-ce: 19;
}
r-grid > r-cell[span$='+20'],
r-grid > r-cell[span$='-19'],
r-grid > r-cell[span='20'] {
  --grid-ce: 20;
}
r-grid > r-cell[span$='+21'],
r-grid > r-cell[span$='-20'],
r-grid > r-cell[span='21'] {
  --grid-ce: 21;
}
r-grid > r-cell[span$='+22'],
r-grid > r-cell[span$='-21'],
r-grid > r-cell[span='22'] {
  --grid-ce: 22;
}
r-grid > r-cell[span$='+23'],
r-grid > r-cell[span$='-22'],
r-grid > r-cell[span='23'] {
  --grid-ce: 23;
}
r-grid > r-cell[span$='+24'],
r-grid > r-cell[span$='-23'],
r-grid > r-cell[span='24'] {
  --grid-ce: 24;
}
r-grid > r-cell[span$='+25'],
r-grid > r-cell[span$='-24'],
r-grid > r-cell[span='25'] {
  --grid-ce: 25;
}
r-grid > r-cell[span$='+26'],
r-grid > r-cell[span$='-25'],
r-grid > r-cell[span='26'] {
  --grid-ce: 26;
}
r-grid > r-cell[span$='+27'],
r-grid > r-cell[span$='-26'],
r-grid > r-cell[span='27'] {
  --grid-ce: 27;
}
r-grid > r-cell[span$='+28'],
r-grid > r-cell[span$='-27'],
r-grid > r-cell[span='28'] {
  --grid-ce: 28;
}
r-grid > r-cell[span$='+29'],
r-grid > r-cell[span$='-28'],
r-grid > r-cell[span='29'] {
  --grid-ce: 29;
}
r-grid > r-cell[span$='+30'],
r-grid > r-cell[span$='-29'],
r-grid > r-cell[span='30'] {
  --grid-ce: 30;
}
r-grid > r-cell[span$='-30'] {
  --grid-ce: 31;
}
r-grid > r-cell[span] {
  grid-column-end: span var(--grid-ce);
}
r-grid > r-cell[span*='+'],
r-grid > r-cell[span*='-'],
r-grid > r-cell[span*='..'] {
  grid-column-start: var(--grid-cs);
}
r-grid > r-cell[span*='-'],
r-grid > r-cell[span*='..'] {
  grid-column-end: var(--grid-ce);
}
r-grid > r-cell[span='row'] {
  grid-column: 1/-1;
}
@media only screen and (max-width: 600px) {
  r-grid[columns-s='1'] {
    --grid-tc: repeat(1, 1fr);
  }
  r-grid[columns-s='2'] {
    --grid-tc: repeat(2, 1fr);
  }
  r-grid[columns-s='3'] {
    --grid-tc: repeat(3, 1fr);
  }
  r-grid[columns-s='4'] {
    --grid-tc: repeat(4, 1fr);
  }
  r-grid[columns-s='5'] {
    --grid-tc: repeat(5, 1fr);
  }
  r-grid[columns-s='6'] {
    --grid-tc: repeat(6, 1fr);
  }
  r-grid[columns-s='7'] {
    --grid-tc: repeat(7, 1fr);
  }
  r-grid[columns-s='8'] {
    --grid-tc: repeat(8, 1fr);
  }
  r-grid[columns-s='9'] {
    --grid-tc: repeat(9, 1fr);
  }
  r-grid[columns-s='10'] {
    --grid-tc: repeat(10, 1fr);
  }
  r-grid[columns-s='11'] {
    --grid-tc: repeat(11, 1fr);
  }
  r-grid[columns-s='12'] {
    --grid-tc: repeat(12, 1fr);
  }
  r-grid[columns-s='13'] {
    --grid-tc: repeat(13, 1fr);
  }
  r-grid[columns-s='14'] {
    --grid-tc: repeat(14, 1fr);
  }
  r-grid[columns-s='15'] {
    --grid-tc: repeat(15, 1fr);
  }
  r-grid[columns-s='16'] {
    --grid-tc: repeat(16, 1fr);
  }
  r-grid[columns-s='17'] {
    --grid-tc: repeat(17, 1fr);
  }
  r-grid[columns-s='18'] {
    --grid-tc: repeat(18, 1fr);
  }
  r-grid[columns-s='19'] {
    --grid-tc: repeat(19, 1fr);
  }
  r-grid[columns-s='20'] {
    --grid-tc: repeat(20, 1fr);
  }
  r-grid[columns-s='21'] {
    --grid-tc: repeat(21, 1fr);
  }
  r-grid[columns-s='22'] {
    --grid-tc: repeat(22, 1fr);
  }
  r-grid[columns-s='23'] {
    --grid-tc: repeat(23, 1fr);
  }
  r-grid[columns-s='24'] {
    --grid-tc: repeat(24, 1fr);
  }
  r-grid[columns-s='25'] {
    --grid-tc: repeat(25, 1fr);
  }
  r-grid[columns-s='26'] {
    --grid-tc: repeat(26, 1fr);
  }
  r-grid[columns-s='27'] {
    --grid-tc: repeat(27, 1fr);
  }
  r-grid[columns-s='28'] {
    --grid-tc: repeat(28, 1fr);
  }
  r-grid[columns-s='29'] {
    --grid-tc: repeat(29, 1fr);
  }
  r-grid[columns-s='30'] {
    --grid-tc: repeat(30, 1fr);
  }
  r-grid > r-cell[span-s^='1'] {
    --grid-cs: 1;
  }
  r-grid > r-cell[span-s^='2'] {
    --grid-cs: 2;
  }
  r-grid > r-cell[span-s^='3'] {
    --grid-cs: 3;
  }
  r-grid > r-cell[span-s^='4'] {
    --grid-cs: 4;
  }
  r-grid > r-cell[span-s^='5'] {
    --grid-cs: 5;
  }
  r-grid > r-cell[span-s^='6'] {
    --grid-cs: 6;
  }
  r-grid > r-cell[span-s^='7'] {
    --grid-cs: 7;
  }
  r-grid > r-cell[span-s^='8'] {
    --grid-cs: 8;
  }
  r-grid > r-cell[span-s^='9'] {
    --grid-cs: 9;
  }
  r-grid > r-cell[span-s^='10'] {
    --grid-cs: 10;
  }
  r-grid > r-cell[span-s^='11'] {
    --grid-cs: 11;
  }
  r-grid > r-cell[span-s^='12'] {
    --grid-cs: 12;
  }
  r-grid > r-cell[span-s^='13'] {
    --grid-cs: 13;
  }
  r-grid > r-cell[span-s^='14'] {
    --grid-cs: 14;
  }
  r-grid > r-cell[span-s^='15'] {
    --grid-cs: 15;
  }
  r-grid > r-cell[span-s^='16'] {
    --grid-cs: 16;
  }
  r-grid > r-cell[span-s^='17'] {
    --grid-cs: 17;
  }
  r-grid > r-cell[span-s^='18'] {
    --grid-cs: 18;
  }
  r-grid > r-cell[span-s^='19'] {
    --grid-cs: 19;
  }
  r-grid > r-cell[span-s^='20'] {
    --grid-cs: 20;
  }
  r-grid > r-cell[span-s^='21'] {
    --grid-cs: 21;
  }
  r-grid > r-cell[span-s^='22'] {
    --grid-cs: 22;
  }
  r-grid > r-cell[span-s^='23'] {
    --grid-cs: 23;
  }
  r-grid > r-cell[span-s^='24'] {
    --grid-cs: 24;
  }
  r-grid > r-cell[span-s^='25'] {
    --grid-cs: 25;
  }
  r-grid > r-cell[span-s^='26'] {
    --grid-cs: 26;
  }
  r-grid > r-cell[span-s^='27'] {
    --grid-cs: 27;
  }
  r-grid > r-cell[span-s^='28'] {
    --grid-cs: 28;
  }
  r-grid > r-cell[span-s^='29'] {
    --grid-cs: 29;
  }
  r-grid > r-cell[span-s^='30'] {
    --grid-cs: 30;
  }
  r-grid > r-cell[span-s$='+1'],
  r-grid > r-cell[span-s='1'] {
    --grid-ce: 1;
  }
  r-grid > r-cell[span-s$='+2'],
  r-grid > r-cell[span-s$='-1'],
  r-grid > r-cell[span-s='2'] {
    --grid-ce: 2;
  }
  r-grid > r-cell[span-s$='+3'],
  r-grid > r-cell[span-s$='-2'],
  r-grid > r-cell[span-s='3'] {
    --grid-ce: 3;
  }
  r-grid > r-cell[span-s$='+4'],
  r-grid > r-cell[span-s$='-3'],
  r-grid > r-cell[span-s='4'] {
    --grid-ce: 4;
  }
  r-grid > r-cell[span-s$='+5'],
  r-grid > r-cell[span-s$='-4'],
  r-grid > r-cell[span-s='5'] {
    --grid-ce: 5;
  }
  r-grid > r-cell[span-s$='+6'],
  r-grid > r-cell[span-s$='-5'],
  r-grid > r-cell[span-s='6'] {
    --grid-ce: 6;
  }
  r-grid > r-cell[span-s$='+7'],
  r-grid > r-cell[span-s$='-6'],
  r-grid > r-cell[span-s='7'] {
    --grid-ce: 7;
  }
  r-grid > r-cell[span-s$='+8'],
  r-grid > r-cell[span-s$='-7'],
  r-grid > r-cell[span-s='8'] {
    --grid-ce: 8;
  }
  r-grid > r-cell[span-s$='+9'],
  r-grid > r-cell[span-s$='-8'],
  r-grid > r-cell[span-s='9'] {
    --grid-ce: 9;
  }
  r-grid > r-cell[span-s$='+10'],
  r-grid > r-cell[span-s$='-9'],
  r-grid > r-cell[span-s='10'] {
    --grid-ce: 10;
  }
  r-grid > r-cell[span-s$='+11'],
  r-grid > r-cell[span-s$='-10'],
  r-grid > r-cell[span-s='11'] {
    --grid-ce: 11;
  }
  r-grid > r-cell[span-s$='+12'],
  r-grid > r-cell[span-s$='-11'],
  r-grid > r-cell[span-s='12'] {
    --grid-ce: 12;
  }
  r-grid > r-cell[span-s$='+13'],
  r-grid > r-cell[span-s$='-12'],
  r-grid > r-cell[span-s='13'] {
    --grid-ce: 13;
  }
  r-grid > r-cell[span-s$='+14'],
  r-grid > r-cell[span-s$='-13'],
  r-grid > r-cell[span-s='14'] {
    --grid-ce: 14;
  }
  r-grid > r-cell[span-s$='+15'],
  r-grid > r-cell[span-s$='-14'],
  r-grid > r-cell[span-s='15'] {
    --grid-ce: 15;
  }
  r-grid > r-cell[span-s$='+16'],
  r-grid > r-cell[span-s$='-15'],
  r-grid > r-cell[span-s='16'] {
    --grid-ce: 16;
  }
  r-grid > r-cell[span-s$='+17'],
  r-grid > r-cell[span-s$='-16'],
  r-grid > r-cell[span-s='17'] {
    --grid-ce: 17;
  }
  r-grid > r-cell[span-s$='+18'],
  r-grid > r-cell[span-s$='-17'],
  r-grid > r-cell[span-s='18'] {
    --grid-ce: 18;
  }
  r-grid > r-cell[span-s$='+19'],
  r-grid > r-cell[span-s$='-18'],
  r-grid > r-cell[span-s='19'] {
    --grid-ce: 19;
  }
  r-grid > r-cell[span-s$='+20'],
  r-grid > r-cell[span-s$='-19'],
  r-grid > r-cell[span-s='20'] {
    --grid-ce: 20;
  }
  r-grid > r-cell[span-s$='+21'],
  r-grid > r-cell[span-s$='-20'],
  r-grid > r-cell[span-s='21'] {
    --grid-ce: 21;
  }
  r-grid > r-cell[span-s$='+22'],
  r-grid > r-cell[span-s$='-21'],
  r-grid > r-cell[span-s='22'] {
    --grid-ce: 22;
  }
  r-grid > r-cell[span-s$='+23'],
  r-grid > r-cell[span-s$='-22'],
  r-grid > r-cell[span-s='23'] {
    --grid-ce: 23;
  }
  r-grid > r-cell[span-s$='+24'],
  r-grid > r-cell[span-s$='-23'],
  r-grid > r-cell[span-s='24'] {
    --grid-ce: 24;
  }
  r-grid > r-cell[span-s$='+25'],
  r-grid > r-cell[span-s$='-24'],
  r-grid > r-cell[span-s='25'] {
    --grid-ce: 25;
  }
  r-grid > r-cell[span-s$='+26'],
  r-grid > r-cell[span-s$='-25'],
  r-grid > r-cell[span-s='26'] {
    --grid-ce: 26;
  }
  r-grid > r-cell[span-s$='+27'],
  r-grid > r-cell[span-s$='-26'],
  r-grid > r-cell[span-s='27'] {
    --grid-ce: 27;
  }
  r-grid > r-cell[span-s$='+28'],
  r-grid > r-cell[span-s$='-27'],
  r-grid > r-cell[span-s='28'] {
    --grid-ce: 28;
  }
  r-grid > r-cell[span-s$='+29'],
  r-grid > r-cell[span-s$='-28'],
  r-grid > r-cell[span-s='29'] {
    --grid-ce: 29;
  }
  r-grid > r-cell[span-s$='+30'],
  r-grid > r-cell[span-s$='-29'],
  r-grid > r-cell[span-s='30'] {
    --grid-ce: 30;
  }
  r-grid > r-cell[span-s$='-30'] {
    --grid-ce: 31;
  }
  r-grid > r-cell[span-s] {
    grid-column-end: span var(--grid-ce);
  }
  r-grid > r-cell[span-s*='+'],
  r-grid > r-cell[span-s*='-'],
  r-grid > r-cell[span-s*='..'] {
    grid-column-start: var(--grid-cs);
  }
  r-grid > r-cell[span-s*='-'],
  r-grid > r-cell[span-s*='..'] {
    grid-column-end: var(--grid-ce);
  }
  r-grid > r-cell[span-s='row'] {
    grid-column: 1/-1;
  }
}
@media only screen and (min-width: 1599px) {
  r-grid[columns-l='1'] {
    --grid-tc: repeat(1, 1fr);
  }
  r-grid[columns-l='2'] {
    --grid-tc: repeat(2, 1fr);
  }
  r-grid[columns-l='3'] {
    --grid-tc: repeat(3, 1fr);
  }
  r-grid[columns-l='4'] {
    --grid-tc: repeat(4, 1fr);
  }
  r-grid[columns-l='5'] {
    --grid-tc: repeat(5, 1fr);
  }
  r-grid[columns-l='6'] {
    --grid-tc: repeat(6, 1fr);
  }
  r-grid[columns-l='7'] {
    --grid-tc: repeat(7, 1fr);
  }
  r-grid[columns-l='8'] {
    --grid-tc: repeat(8, 1fr);
  }
  r-grid[columns-l='9'] {
    --grid-tc: repeat(9, 1fr);
  }
  r-grid[columns-l='10'] {
    --grid-tc: repeat(10, 1fr);
  }
  r-grid[columns-l='11'] {
    --grid-tc: repeat(11, 1fr);
  }
  r-grid[columns-l='12'] {
    --grid-tc: repeat(12, 1fr);
  }
  r-grid[columns-l='13'] {
    --grid-tc: repeat(13, 1fr);
  }
  r-grid[columns-l='14'] {
    --grid-tc: repeat(14, 1fr);
  }
  r-grid[columns-l='15'] {
    --grid-tc: repeat(15, 1fr);
  }
  r-grid[columns-l='16'] {
    --grid-tc: repeat(16, 1fr);
  }
  r-grid[columns-l='17'] {
    --grid-tc: repeat(17, 1fr);
  }
  r-grid[columns-l='18'] {
    --grid-tc: repeat(18, 1fr);
  }
  r-grid[columns-l='19'] {
    --grid-tc: repeat(19, 1fr);
  }
  r-grid[columns-l='20'] {
    --grid-tc: repeat(20, 1fr);
  }
  r-grid[columns-l='21'] {
    --grid-tc: repeat(21, 1fr);
  }
  r-grid[columns-l='22'] {
    --grid-tc: repeat(22, 1fr);
  }
  r-grid[columns-l='23'] {
    --grid-tc: repeat(23, 1fr);
  }
  r-grid[columns-l='24'] {
    --grid-tc: repeat(24, 1fr);
  }
  r-grid[columns-l='25'] {
    --grid-tc: repeat(25, 1fr);
  }
  r-grid[columns-l='26'] {
    --grid-tc: repeat(26, 1fr);
  }
  r-grid[columns-l='27'] {
    --grid-tc: repeat(27, 1fr);
  }
  r-grid[columns-l='28'] {
    --grid-tc: repeat(28, 1fr);
  }
  r-grid[columns-l='29'] {
    --grid-tc: repeat(29, 1fr);
  }
  r-grid[columns-l='30'] {
    --grid-tc: repeat(30, 1fr);
  }
  r-grid > r-cell[span-l^='1'] {
    --grid-cs: 1;
  }
  r-grid > r-cell[span-l^='2'] {
    --grid-cs: 2;
  }
  r-grid > r-cell[span-l^='3'] {
    --grid-cs: 3;
  }
  r-grid > r-cell[span-l^='4'] {
    --grid-cs: 4;
  }
  r-grid > r-cell[span-l^='5'] {
    --grid-cs: 5;
  }
  r-grid > r-cell[span-l^='6'] {
    --grid-cs: 6;
  }
  r-grid > r-cell[span-l^='7'] {
    --grid-cs: 7;
  }
  r-grid > r-cell[span-l^='8'] {
    --grid-cs: 8;
  }
  r-grid > r-cell[span-l^='9'] {
    --grid-cs: 9;
  }
  r-grid > r-cell[span-l^='10'] {
    --grid-cs: 10;
  }
  r-grid > r-cell[span-l^='11'] {
    --grid-cs: 11;
  }
  r-grid > r-cell[span-l^='12'] {
    --grid-cs: 12;
  }
  r-grid > r-cell[span-l^='13'] {
    --grid-cs: 13;
  }
  r-grid > r-cell[span-l^='14'] {
    --grid-cs: 14;
  }
  r-grid > r-cell[span-l^='15'] {
    --grid-cs: 15;
  }
  r-grid > r-cell[span-l^='16'] {
    --grid-cs: 16;
  }
  r-grid > r-cell[span-l^='17'] {
    --grid-cs: 17;
  }
  r-grid > r-cell[span-l^='18'] {
    --grid-cs: 18;
  }
  r-grid > r-cell[span-l^='19'] {
    --grid-cs: 19;
  }
  r-grid > r-cell[span-l^='20'] {
    --grid-cs: 20;
  }
  r-grid > r-cell[span-l^='21'] {
    --grid-cs: 21;
  }
  r-grid > r-cell[span-l^='22'] {
    --grid-cs: 22;
  }
  r-grid > r-cell[span-l^='23'] {
    --grid-cs: 23;
  }
  r-grid > r-cell[span-l^='24'] {
    --grid-cs: 24;
  }
  r-grid > r-cell[span-l^='25'] {
    --grid-cs: 25;
  }
  r-grid > r-cell[span-l^='26'] {
    --grid-cs: 26;
  }
  r-grid > r-cell[span-l^='27'] {
    --grid-cs: 27;
  }
  r-grid > r-cell[span-l^='28'] {
    --grid-cs: 28;
  }
  r-grid > r-cell[span-l^='29'] {
    --grid-cs: 29;
  }
  r-grid > r-cell[span-l^='30'] {
    --grid-cs: 30;
  }
  r-grid > r-cell[span-l$='+1'],
  r-grid > r-cell[span-l='1'] {
    --grid-ce: 1;
  }
  r-grid > r-cell[span-l$='+2'],
  r-grid > r-cell[span-l$='-1'],
  r-grid > r-cell[span-l='2'] {
    --grid-ce: 2;
  }
  r-grid > r-cell[span-l$='+3'],
  r-grid > r-cell[span-l$='-2'],
  r-grid > r-cell[span-l='3'] {
    --grid-ce: 3;
  }
  r-grid > r-cell[span-l$='+4'],
  r-grid > r-cell[span-l$='-3'],
  r-grid > r-cell[span-l='4'] {
    --grid-ce: 4;
  }
  r-grid > r-cell[span-l$='+5'],
  r-grid > r-cell[span-l$='-4'],
  r-grid > r-cell[span-l='5'] {
    --grid-ce: 5;
  }
  r-grid > r-cell[span-l$='+6'],
  r-grid > r-cell[span-l$='-5'],
  r-grid > r-cell[span-l='6'] {
    --grid-ce: 6;
  }
  r-grid > r-cell[span-l$='+7'],
  r-grid > r-cell[span-l$='-6'],
  r-grid > r-cell[span-l='7'] {
    --grid-ce: 7;
  }
  r-grid > r-cell[span-l$='+8'],
  r-grid > r-cell[span-l$='-7'],
  r-grid > r-cell[span-l='8'] {
    --grid-ce: 8;
  }
  r-grid > r-cell[span-l$='+9'],
  r-grid > r-cell[span-l$='-8'],
  r-grid > r-cell[span-l='9'] {
    --grid-ce: 9;
  }
  r-grid > r-cell[span-l$='+10'],
  r-grid > r-cell[span-l$='-9'],
  r-grid > r-cell[span-l='10'] {
    --grid-ce: 10;
  }
  r-grid > r-cell[span-l$='+11'],
  r-grid > r-cell[span-l$='-10'],
  r-grid > r-cell[span-l='11'] {
    --grid-ce: 11;
  }
  r-grid > r-cell[span-l$='+12'],
  r-grid > r-cell[span-l$='-11'],
  r-grid > r-cell[span-l='12'] {
    --grid-ce: 12;
  }
  r-grid > r-cell[span-l$='+13'],
  r-grid > r-cell[span-l$='-12'],
  r-grid > r-cell[span-l='13'] {
    --grid-ce: 13;
  }
  r-grid > r-cell[span-l$='+14'],
  r-grid > r-cell[span-l$='-13'],
  r-grid > r-cell[span-l='14'] {
    --grid-ce: 14;
  }
  r-grid > r-cell[span-l$='+15'],
  r-grid > r-cell[span-l$='-14'],
  r-grid > r-cell[span-l='15'] {
    --grid-ce: 15;
  }
  r-grid > r-cell[span-l$='+16'],
  r-grid > r-cell[span-l$='-15'],
  r-grid > r-cell[span-l='16'] {
    --grid-ce: 16;
  }
  r-grid > r-cell[span-l$='+17'],
  r-grid > r-cell[span-l$='-16'],
  r-grid > r-cell[span-l='17'] {
    --grid-ce: 17;
  }
  r-grid > r-cell[span-l$='+18'],
  r-grid > r-cell[span-l$='-17'],
  r-grid > r-cell[span-l='18'] {
    --grid-ce: 18;
  }
  r-grid > r-cell[span-l$='+19'],
  r-grid > r-cell[span-l$='-18'],
  r-grid > r-cell[span-l='19'] {
    --grid-ce: 19;
  }
  r-grid > r-cell[span-l$='+20'],
  r-grid > r-cell[span-l$='-19'],
  r-grid > r-cell[span-l='20'] {
    --grid-ce: 20;
  }
  r-grid > r-cell[span-l$='+21'],
  r-grid > r-cell[span-l$='-20'],
  r-grid > r-cell[span-l='21'] {
    --grid-ce: 21;
  }
  r-grid > r-cell[span-l$='+22'],
  r-grid > r-cell[span-l$='-21'],
  r-grid > r-cell[span-l='22'] {
    --grid-ce: 22;
  }
  r-grid > r-cell[span-l$='+23'],
  r-grid > r-cell[span-l$='-22'],
  r-grid > r-cell[span-l='23'] {
    --grid-ce: 23;
  }
  r-grid > r-cell[span-l$='+24'],
  r-grid > r-cell[span-l$='-23'],
  r-grid > r-cell[span-l='24'] {
    --grid-ce: 24;
  }
  r-grid > r-cell[span-l$='+25'],
  r-grid > r-cell[span-l$='-24'],
  r-grid > r-cell[span-l='25'] {
    --grid-ce: 25;
  }
  r-grid > r-cell[span-l$='+26'],
  r-grid > r-cell[span-l$='-25'],
  r-grid > r-cell[span-l='26'] {
    --grid-ce: 26;
  }
  r-grid > r-cell[span-l$='+27'],
  r-grid > r-cell[span-l$='-26'],
  r-grid > r-cell[span-l='27'] {
    --grid-ce: 27;
  }
  r-grid > r-cell[span-l$='+28'],
  r-grid > r-cell[span-l$='-27'],
  r-grid > r-cell[span-l='28'] {
    --grid-ce: 28;
  }
  r-grid > r-cell[span-l$='+29'],
  r-grid > r-cell[span-l$='-28'],
  r-grid > r-cell[span-l='29'] {
    --grid-ce: 29;
  }
  r-grid > r-cell[span-l$='+30'],
  r-grid > r-cell[span-l$='-29'],
  r-grid > r-cell[span-l='30'] {
    --grid-ce: 30;
  }
  r-grid > r-cell[span-l$='-30'] {
    --grid-ce: 31;
  }
  r-grid > r-cell[span-l] {
    grid-column-end: span var(--grid-ce);
  }
  r-grid > r-cell[span-l*='+'],
  r-grid > r-cell[span-l*='-'],
  r-grid > r-cell[span-l*='..'] {
    grid-column-start: var(--grid-cs);
  }
  r-grid > r-cell[span-l*='-'],
  r-grid > r-cell[span-l*='..'] {
    grid-column-end: var(--grid-ce);
  }
  r-grid > r-cell[span-l='row'] {
    grid-column: 1/-1;
  }
}
r-grid.debug > * {
  --color: rgba(248, 110, 91, 0.3);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--color)),
    to(var(--color))
  );
  background-image: linear-gradient(180deg, var(--color) 0, var(--color));
}
r-grid.debug > :nth-child(6n + 2) {
  --color: rgba(103, 126, 208, 0.3);
}
r-grid.debug > :nth-child(6n + 3) {
  --color: rgba(224, 174, 72, 0.3);
}
r-grid.debug > :nth-child(6n + 4) {
  --color: rgba(77, 214, 115, 0.3);
}
r-grid.debug > :nth-child(6n + 5) {
  --color: rgba(217, 103, 219, 0.3);
}
r-grid.debug > :nth-child(6n + 6) {
  --color: rgba(94, 204, 211, 0.3);
}
r-grid.debug > :nth-child(6n + 7) {
  --color: rgba(248, 110, 91, 0.3);
}
